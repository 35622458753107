import { createContext } from 'react';
import { AppContextType } from './types';
import { DdcClient } from '../../lib/ddc/ddc-client';
import { DavinciClient } from '../../lib/davinci/davinci-client';
import { CereEtlServiceClient } from '../../lib/cere-etl-service';
import { EventService } from '../../lib/event-service';

declare const DDC_BOOTNODE: string;

export const AppContext = createContext<AppContextType>({
  login: () => null,
  logout: () => null,
  updateSubscription: () => Promise.resolve(),
  user: null,
  tiers: [],
  token: '',
  ddcClient: new DdcClient(DDC_BOOTNODE),
  davinciClient: new DavinciClient(process.env.REACT_APP_DAVINCI_URL!, process.env.REACT_APP_MEDIA_API_URL!),
  eventService: new EventService(
    new CereEtlServiceClient(
      process.env.REACT_APP_ETL_SERVICE_URL || 'https://dev-ai-etl-service.network-dev.aws.cere.io/',
    ),
    process.env.REACT_APP_DDC_ACCOUNT || '//Alice',
    process.env.REACT_APP_DDC_ENVIRONMENT || 'CUSTOM',
  ),
  decrypt: () => Promise.resolve(undefined),
  encrypt: () => Promise.resolve(undefined),
});
